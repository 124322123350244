<template>
  <div>
    <b-card>
      <b-row>
        <b-col
          cols="12"
        >
          <title-text />
        </b-col>
        <b-col
          cols="12"
        >
          <sub-title-text />
        </b-col>
        <b-col
          cols="12"
        >
          <content-text />
        </b-col>
        <b-col
          cols="12"
          md="4"
        >
          <button-label />
        </b-col>
        <b-col
          cols="12"
          md="4"
        >
          <button-url />
        </b-col>
        <b-col
          cols="12"
          md="4"
        >
          <ordering-number />
        </b-col>
        <b-col
          cols="12"
          md="auto"
        >
          <text-status />
        </b-col>
        <b-col
          cols="12"
          md="auto"
        >
          <overlay-status />
        </b-col>
      </b-row>
    </b-card>
    <image-card />
    <b-button
      variant="primary"
      :disabled="itemData.submitStatus"
      @click="submitForm"
    >
      Kaydet
    </b-button>
  </div>
</template>

<script>
import {
  BCard, BCol, BRow, BButton,
} from 'bootstrap-vue'
import TitleText from '@/views/Admin/Carousel/elements/TitleText.vue'
import SubTitleText from '@/views/Admin/Carousel/elements/SubTitleText.vue'
import ContentText from '@/views/Admin/Carousel/elements/ContentText.vue'
import ButtonLabel from '@/views/Admin/Carousel/elements/ButtonLabel.vue'
import ButtonUrl from '@/views/Admin/Carousel/elements/ButtonUrl.vue'
import OrderingNumber from '@/views/Admin/Carousel/elements/OrderingNumber.vue'
import TextStatus from '@/views/Admin/Carousel/elements/TextStatus.vue'
import OverlayStatus from '@/views/Admin/Carousel/elements/OverlayStatus.vue'
import ImageCard from '@/views/Admin/Carousel/elements/ImageCard.vue'

export default {
  name: 'ItemForm',
  components: {
    BCard,
    BRow,
    BCol,
    BButton,
    TitleText,
    SubTitleText,
    ContentText,
    ButtonLabel,
    ButtonUrl,
    OrderingNumber,
    TextStatus,
    OverlayStatus,
    ImageCard,
  },
  props: {
    submitForm: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      mediaType: 'image',
      cropperSettings: {
        modalStatus: false,
        fileName: null,
        folder: null,
        ratio: null,
        type: null,
      },
    }
  },
  computed: {
    itemData() {
      return this.$store.getters['carousel/dataItem']
    },
  },
  mounted() {
    this.itemData.submitStatus = false
  },
}
</script>
