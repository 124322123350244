<template>
  <div>
    <b-row>
      <b-col
        cols="12"
        md="8"
      >
        <b-card title="Masaüstü Görsel">
          <b-img
            v-if="itemData.image"
            :src="baseURL + '/media/carousel/' + itemData.image"
            thumbnail
            center
          />
          <div class="text-center mt-1">
            <b-button
              variant="primary"
              @click="setModal('desktop')"
            >
              <FeatherIcon icon="UploadIcon" />
              Görsel Yükle
            </b-button>
          </div>
        </b-card>
      </b-col>
      <b-col
        cols="12"
        md="4"
      >
        <b-card title="Mobil Görsel">
          <b-img
            v-if="itemData.mobile_image"
            :src="baseURL + '/media/carousel/' + itemData.mobile_image"
            thumbnail
            center
          />
          <div class="text-center mt-1">
            <b-button
              variant="primary"
              @click="setModal('mobile')"
            >
              <FeatherIcon icon="UploadIcon" />
              Görsel Yükle
            </b-button>
          </div>
        </b-card>
      </b-col>
    </b-row>
    <cropper-image
      v-if="cropperSettings.type"
      :modal-status="cropperSettings.modalStatus"
      :close-modal="closeModal"
      :file-name="cropperSettings.fileName"
      :folder="cropperSettings.folder"
      :ratio="cropperSettings.ratio"
      :width-size="cropperSettings.widthSize"
      component="carousel"
      :params="cropperSettings.type"
      :item-id="itemData.id"
      @uploadImage="setUploadImage"
    />
  </div>
</template>

<script>
import {
  BButton, BCard, BCol, BImg, BRow,
} from 'bootstrap-vue'
import CropperImage from '@/layouts/components/common/CropperImage.vue'

export default {
  name: 'ImageCard',
  components: {
    CropperImage,
    BRow,
    BCol,
    BButton,
    BImg,
    BCard,
  },
  data() {
    return {
      baseURL: this.$store.state.app.baseURL,
      cropperSettings: {
        modalStatus: false,
        fileName: null,
        folder: null,
        ratio: null,
        widthSize: null,
        type: null,
      },
    }
  },
  computed: {
    itemData() {
      return this.$store.getters['carousel/dataItem']
    },
  },
  methods: {
    setModal(type) {
      if (type === 'desktop') {
        this.cropperSettings = {
          modalStatus: true,
          fileName: 'carousel-desktop-image.jpg',
          folder: 'carousel',
          ratio: 16 / 9,
          widthSize: 1920,
          type: 'desktop',
        }
      }
      if (type === 'mobile') {
        this.cropperSettings = {
          modalStatus: true,
          fileName: 'carousel-mobile-image.jpg',
          folder: 'carousel',
          ratio: 9 / 16,
          widthSize: 1080,
          type: 'mobile',
        }
      }
    },
    closeModal() {
      this.cropperSettings = {
        modalStatus: false,
        fileName: null,
        folder: null,
        ratio: null,
        type: null,
      }
    },
    setUploadImage(item) {
      if (this.cropperSettings.type === 'desktop') {
        this.itemData.image = item.filename
        this.itemData.thumb = item.thumb
        this.itemData.image_webp = item.filename_webp
        this.itemData.thumb_webp = item.thumb_webp
        this.itemData.mediaData = item
      }
      if (this.cropperSettings.type === 'mobile') {
        this.itemData.mobile_image = item.filename
        this.itemData.mobile_thumb = item.thumb
        this.itemData.mobile_image_webp = item.filename_webp
        this.itemData.mobile_thumb_webp = item.thumb_webp
        this.itemData.mobile_mediaData = item
      }
      this.cropperSettings.modalStatus = false
    },
  },
}
</script>
