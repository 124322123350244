<template>
  <div>
    <b-form-group
      label="Görsel Üzerinde Filtre"
      label-for="overlay_status"
    >
      <b-form-checkbox
        id="overlay_status"
        v-model="itemData.overlay_status"
        value="1"
        switch
      >
        {{ itemData.overlay_status === '1'? 'Aktif' : 'Pasif' }}
      </b-form-checkbox>
    </b-form-group>
  </div>
</template>

<script>
import { BFormGroup, BFormCheckbox } from 'bootstrap-vue'

export default {
  name: 'TextStatus',
  components: { BFormGroup, BFormCheckbox },
  computed: {
    itemData() {
      return this.$store.getters['carousel/dataItem']
    },
  },
}
</script>
